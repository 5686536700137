
require('../scss/app.scss');

require('bootstrap');
require('@popperjs/core');
require('@fortawesome/fontawesome-free/css/all.min.css');
//require('@fortawesome/fontawesome-free/js/all.js');

document.addEventListener("DOMContentLoaded", function () {

});
